import styled from 'styled-components';

export const ArticleImageWrapper = styled.div`
  img {
    border-radius: 12px;
  }
`;

export const ArticleContentPreview = styled.div``;

export const ArticleTitle = styled.h2`
  color: #000;
  margin-top: 20px;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: -0.62px;
  line-height: 56px;
  margin-bottom: 20px;
`;

export const ArticleSummary = styled.div`
  color: #000;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 31px;
`;

export const ArticleMetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
`;

export const ArticleAuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

export const ArticleAuthor = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.fonts.Walsheim.medium};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;
  margin-bottom: 12px;
  margin-top: 8px;
`;

export const ArticlePublicationInfo = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 11px;
`;

export const ArticlePreviewWrapper = styled.div`
  padding: 20px;
  margin-bottom: 70px;
  width: 50%;
  border-radius: 25px;
  position: relative;
  ${({ theme }) => theme.BreakpointMobile`
    width: 100%;
    margin-bottom: 20px;
  `};
  &:first-of-type {
    width: 100%;
    margin-bottom: 220px;
    ${({ theme }) => theme.BreakpointTablet`
      margin-bottom: 20px;
    `};
    ${ArticleContentPreview} {
      position: absolute;
      bottom: -100px;
      width: 100%;
      background-color: #fff;
      border-radius: 16px;
      max-width: 957px;
      left: 50%;
      transform: translateX(-50%);
      ${({ theme }) => theme.BreakpointTablet`
        position: relative;
        transform: unset;
        left: unset;
        bottom: unset;
      `};
    }
    ${ArticleTitle} {
      font-size: 60px;
      font-weight: 900;
      font-family: ${({ theme }) => theme.fonts.Walsheim.black};
      letter-spacing: -0.94px;
      line-height: 73px;
      text-align: center;
      margin-top: 50px;
      ${({ theme }) => theme.BreakpointMobile`
        margin-top: 20px;
        font-size: 42px;
        font-weight: bold;
        letter-spacing: -0.62px;
        line-height: 56px;
        text-align: left;
      `};
    }
    ${ArticleSummary} {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      padding: 0 50px;
      ${({ theme }) => theme.BreakpointMobile`
        font-size: 21px;
        line-height: 31px;
        text-align: left;
        padding: unset;
      `};
    }
    ${ArticleMetaWrapper} {
      justify-content: center;
      margin-top: 40px;
      ${({ theme }) => theme.BreakpointMobile`
        justify-content: start;
        margin-top: 20px;
      `};
    }
  }
`;
