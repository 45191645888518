import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  ArticleAuthor,
  ArticleAuthorWrapper,
  ArticleMetaWrapper,
  ArticlePublicationInfo,
  ArticleSummary,
} from '@components/Blog/ArticlePreview/ArticlePreviewStyled';
import { ArticleQuery } from 'graphql-types';
import { SectionTopLevelWrapper } from '../SectionStyled';
import {
  ArticleIntroWrapper,
  ArticleTitle,
  ArticleIntroImageWrapper,
} from './ArticleIntroSectionStyled';

const ArticleIntroSection: React.FC<ArticleQuery['article']> = (article) => {
  const { titleImage, title, summary, author, publishedAt } = article;
  return (
    <SectionTopLevelWrapper>
      <ArticleIntroWrapper>
        <ArticleTitle>{title.localized}</ArticleTitle>
        <ArticleSummary>{summary.localized}</ArticleSummary>
        <ArticleMetaWrapper>
          {author.image?.asset?.gatsbyImageData && (
            <GatsbyImage image={author.image.asset.gatsbyImageData} alt="" />
          )}
          <ArticleAuthorWrapper>
            <ArticleAuthor>
              {author.firstName}
              {author.lastName && ` ${author.lastName}`}
            </ArticleAuthor>
            <ArticlePublicationInfo>{publishedAt}</ArticlePublicationInfo>
          </ArticleAuthorWrapper>
        </ArticleMetaWrapper>
        <ArticleIntroImageWrapper>
          {titleImage?.asset?.gatsbyImageData && (
            <GatsbyImage image={titleImage.asset.gatsbyImageData} alt="" />
          )}
        </ArticleIntroImageWrapper>
      </ArticleIntroWrapper>
    </SectionTopLevelWrapper>
  );
};

export default ArticleIntroSection;
