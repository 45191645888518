import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import Section from '@components/Sections/Section';
import ArticleIntroSection from '@components/Sections/ArticleIntro/ArticleIntroSection';
import track from '@src/tracking/track';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { ArticleQuery, ArticleQueryVariables } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';

const Article = ({
  data,
  pageContext,
}: PageProps<ArticleQuery, ArticleQueryVariables>) => {
  const { identifier } = data?.article?.tracking ?? {};
  useSaveUtmParamsToLocalStorage();
  useEffect(() => {
    track({
      page: {
        categorySlug: data.article?.blog?.slug?.localized,
        pageSlug: data.article?.slug?.localized,
      },
    });
  }, [data.article?.slug?.localized, data.article?.blog?.slug?.localized]);

  return (
    <PageWrapper
      locale={pageContext.locale}
      identifier={identifier}
      navigationBar={data?.article?.blog?.navigationBar}
      metadata={data?.article?.metadata}
      announcementId={data?.article?.blog?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      {data?.article && <ArticleIntroSection {...data.article} />}
      {data.article.content.map((content) => (
        <Section key={content._key} content={content} />
      ))}
    </PageWrapper>
  );
};

export const query = graphql`
  query Article($id: String!, $locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    article: sanityArticle(_id: { eq: $id }) {
      _id
      _key
      blog {
        navigationBar {
          ...Header
        }
        slug {
          localized(locale: $locale)
        }
      }
      slug {
        localized(locale: $locale)
      }
      title {
        localized(locale: $locale)
      }
      titleImage {
        ...GatsbyImage
      }
      summary {
        localized(locale: $locale)
      }
      author {
        firstName
        lastName
        image {
          asset {
            gatsbyImageData(width: 50, placeholder: BLURRED)
          }
        }
      }
      publishedAt
      content {
        ...ContentSections
      }
      metadata {
        ...SEO
      }
      tracking {
        ...tracking
      }
      redirects
    }
  }
`;

// ts-prune-ignore-next
export default Article;
