import styled from 'styled-components';
import {
  ArticleMetaWrapper,
  ArticleSummary,
} from '@components/Blog/ArticlePreview/ArticlePreviewStyled';

export const ArticleTitle = styled.h1`
  color: #000;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 73px;
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 20px;
`;

export const ArticleIntroImageWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  img {
    border-radius: 12px;
  }
`;

export const ArticleIntroWrapper = styled.div`
  padding: 20px;
  border-radius: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ArticleMetaWrapper} {
    margin: 0 auto;
    margin-top: 27px;
    justify-content: center;
    max-width: 950px;
  }
  ${ArticleSummary} {
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    font-size: 21px;
    line-height: 32px;
  }
`;
